@parcel-import "./variables.css";
@parcel-import "./atoms/line.css";
@parcel-import "./atoms/copyright.css";
@parcel-import "./molecules/hero.css";
@parcel-import "./molecules/header.css";
@parcel-import "./molecules/form.css";
@parcel-import "./organisms/page.css";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  max-height: 100vh;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: var(--font-size-mobile);
  line-height: var(--line-height-mobile);
  background-color: var(--color-darkgray);
  color: var(--color-white);

  @media (min-width: 992px) {
    font-size: var(--font-size);
  line-height: var(--line-height);
  }
}

h1 {
  font-size: var(--font-size-large-mobile);
  font-weight: 400;
  line-height: var(--line-height-large-mobile);
  margin: 0;
  padding: 0;

  text-shadow: var(--text-shadow);
  font-family: var(--font-family-header);

  @media (min-width: 992px) {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }
}

a {
  color: var(--color-white);

  transition: color 0.3s ease-in-out;

  &:visited,
  &:active {
    color: var(--color-white);
  }

  &:hover,
  &:focus {
    color: var(--color-blue);
  }

}

.hide {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}